<template>
    <div class="dynamic-page">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4">
                    <span class="text-muted fw-light">
                        <a href="/">{{ $j('Homepage') }}</a><i class="mdi mdi-chevron-right"></i>
                    </span> {{ $j('Documents') }}
                </h4>
            </div>
            <div class="reference-info pb-3">
                <h2 class="pt-2">{{ $j('docTitle') }}</h2>
            </div>
            <div class="row justify-content-start pb-4">
                <span class="btn_6 cursor-pointer btn_6_active mr-2" @click="changePdf(1)">{{ $j('docButton1') }}</span>
                <span class="btn_6 cursor-pointer mr-2" @click="changePdf(2)">{{ $j('docButton2') }}</span>
                <span class="btn_6 cursor-pointer mr-2" @click="changePdf(3)">{{ $j('docButton3') }}</span>
                <span class="btn_6 cursor-pointer" @click="changePdf(2)">{{ $j('docButton4') }}</span>
            </div>
            <div ref="viewerContainer" class="pdf-viewer-container pb-5">
                <iframe :src="pdfSrc" style="width: 100%; height: 100vh; border: none;"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pdfSrc: '/assets/files/pdf1.pdf',
        };
    },
    methods: {
        changePdf(buttonId){
            this.pdfSrc = '/assets/files/pdf' + buttonId + '.pdf';
            var classList = document.getElementsByClassName("btn_6");
            for (var i = 0; i < classList.length; i++) {
                classList[i].className = classList[i].className.replace(" btn_6_active", "");
            }
            classList[buttonId-1].className += " btn_6_active";
		},
    }
};
</script>